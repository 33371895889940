import * as React from "react"
import LogoMP4 from "../videos/logo.mp4"
import LogoWebM from "../videos/logo.webm"
import styled from "styled-components"

import "../styles/global.css"

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const VideoPlayer = styled.video`
  min-width: 101%;
  margin: 0;
  padding: 0;
  background: white;
  border: none;
`

const LoadingLogo = () => (
  <Container>
    <VideoPlayer autoPlay muted>
      <source src={LogoWebM} type="video/webm" />
      <source src={LogoMP4} type="video/mp4" />
      Doesn't support
    </VideoPlayer>
  </Container>
)

export default LoadingLogo
